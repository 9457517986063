:root {
  --main-color: #fe4a49;
  --secondary-color: #264254;
  --light-gray: #c5c5c5;
}

body {
  font-family: "Inter", sans-serif !important;
  background-color: #f5f5f5;
}

#sidebar ul li:last-child {
  position: absolute;
  bottom: 15%;
}

/* Sidebar Styling */
#sidebar li a.active {
  background-color: var(--secondary-color) !important;
  color: #fff;
  font-weight: 500;
  -webkit-tap-highlight-color: transparent;
}

#sidebar li a.active img {
  filter: brightness(100);
}

#sidebar ul li:last-child a {
  color: #f20100;
}

@media only screen and (max-width: 500px) {
  #sidebar ul li:last-child {
    bottom: 20%;
  }
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  justify-content: center;
}
#mediaPlayer .carousel.carousel-slider {
  overflow: visible;
}
#mediaPlayer .carousel .control-next.control-arrow {
  right: -16px;
}
#mediaPlayer .carousel .control-prev.control-arrow {
  left: -16px;
}
.carousel.carousel-slider .control-arrow {
  top: 50% !important;
  transform: translateY(-50%);
  background: #cbbc64 !important;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .control-dots .dot.selected {
  background: #cbbc64;
}

.carousel .carousel-status {
  display: none;
}

body .carousel .control-dots .dot {
  box-shadow: none;
  border: 1px solid gray;
}
.carousel .control-dots .dot.selected {
  border: none;
}

#mediaPlayer .carousel .control-dots {
  transform: translateY(15px);
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a8b3;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5;
}

.pattern,
.pattern-2 {
  position: absolute;
}

.pattern-2 {
  top: 85%;
  right: -220px;
}

.verify_link_modal,
.reset_pass_modal {
  width: 500px;
}

/* .session_modal {
  max-width: 350px;
} */

#emoji_wrapper {
  height: 320px;
}

#customDate::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.date-container {
  position: absolute;
  right: 0;
  top: 10px;
}

#customDate {
  background: none;
  opacity: 0;
  width: 50px;
}

.recharts-layer tspan {
  font-size: 14px;
  text-transform: lowercase;
}

.recharts-layer tspan {
  text-transform: capitalize;
}

.rti--container {
  border-radius: 50px !important;
  padding: 12px 20px !important;
}

.rti--input {
  outline: none !important;
  border: none !important;
  border-radius: 16px !important;
}

.rti--input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.rti--tag {
  font-size: 14px !important;
}
.RichTextEditor__root___2QXK- {
  height: 230px !important;
  border-radius: 16px !important;
  overflow-y: auto !important;
}

/* Your CSS file */
@keyframes borderAnimationRight {
  0% {
    width: 0%;
    right: 0;
  }
  100% {
    width: 100%;
    right: 0;
  }
}

@keyframes borderAnimationLeft {
  0% {
    width: 0%;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
}

.border-animation-right {
  animation: borderAnimationRight 0.6s forwards;
}

.border-animation-left {
  animation: borderAnimationLeft 0.6s forwards;
}

.css-b62m3t-container input:focus {
  box-shadow: none !important;
}

.react-datepicker__month {
  width: 13rem !important;
}
