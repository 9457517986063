/* Using Google's Inter Font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-inter text-sm 3xl:text-base;
  }
}

@layer utilities {
  .card {
    @apply relative rounded-20 bg-white p-6 shadow-card;
  }

  .card-title {
    @apply text-xl font-semibold text-secondary-color;
  }

  .card-sub-title {
    @apply text-sm text-grayColor300;
  }
  .read-only-input {
    @apply border-0 !px-0 text-base font-medium !text-secondary-color focus:ring-0;
  }
}

.item {
  @apply flex h-8 w-8 shrink-0 cursor-pointer items-center justify-center rounded-full border text-sm font-medium text-primary-color-100;
}

.item.active {
  @apply border-secondary bg-secondary text-white;
}

.pagination-page a {
  @apply flex h-full w-full items-center justify-center;
}

.pagination {
  @apply mb-2 flex items-center justify-end gap-x-2;
}

.react-loading-skeleton {
  z-index: 0 !important;
}

.image-gallery-thumbnails {
  @apply !pt-4;
}
.image-gallery-thumbnail {
  @apply !w-18 rounded-lg !border-none;
}

.image-gallery-thumbnail.active {
  @apply mx-4 scale-[1.1] transform !border-none;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  @apply mb-8 h-[65vh] w-[90vw];
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  @apply h-18 rounded-md border border-grayColor200;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  @apply ml-4;
}
.image-gallery-thumbnails {
  @apply pt-6 pb-6;
}
.image-gallery-thumbnail .active {
  @apply mr-0 border !border-secondary;
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  @apply !ml-3;
}
.image-gallery-left-nav,
.image-gallery-right-nav {
  @apply focus:!border-0 focus:!outline-none focus:!ring-0;
}
.image-gallery-icon {
  @apply hover:!text-gray-500;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  @apply !h-14 !w-14;
}

.react-datepicker__input-container input {
  @apply !w-full !rounded-full focus:outline-none;
}

.clip-healthy {
  /* clip-path: polygon(0 0, 92% 0, 96.3% 54%, 100% 100%, 0 100%, 0% 50%); */
  border-right: 2px solid white;
}

.clip-unhealthy {
  /* clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 4% 100%, 2% 52%); */
  border-left: 2px solid white;
}
